import React, { Component } from 'react'
import { AUTH_TOKEN } from '../../../constants'
import FontAwesome from 'react-fontawesome'
import { withApollo } from 'react-apollo'
import { OPEN_MODAL } from 'graphql/modal-graphql'
import { withRouter } from 'react-router'
import { graphql, Query, compose } from 'react-apollo'
import PopMenu from 'components/PopMenu/PopMenu'

import { RESEND_CONFIRMATION } from 'graphql/all'

class Header extends Component {

  state = {
      isMenuOpen: false,
      hasSentConfirmation: false
  }

  componentWillMount(){
      document.addEventListener('click', this.handleClickOutside, false);
  }

  componentWillUnmount(){
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    if(this.node && !this.node.contains(e.target)){
      this.toggleMenu(false);
    }
  }

  toggleMenu = (openState = null) => {

    const newOpenState = openState !== null ? openState : !this.state.isMenuOpen

    this.setState({
        isMenuOpen: newOpenState
    })
  }

  logOut = () => {
    this.props.client.resetStore()
    localStorage.removeItem(AUTH_TOKEN)
    this.props.history.push('/auth')
  }

  menuItems = [
    {
      label: "Edit profile",
      callback: () => {
        this.props.openModal({
          variables: {
            operation: 'edit',
            dataType: 'user',
            isVisible: true,
            object: this.props.user
        }})
      }
    },
    {
      label: "Edit brokerage",
      callback: () => {
        this.props.openModal({
          variables: {
            operation: 'edit',
            dataType: 'brokerage',
            isVisible: true,
            object: this.props.user.brokerage
        }})
      }
    },{
      label: 'Getting started',
      callback: () => {
        this.props.openModal({
          variables: {
            operation: 'onboard',
            dataType: 'broker',
            isVisible: true,
            object: null
        }})
      }
    },{
      label: "Log out",
      callback: this.logOut
    }
  ]

  resendConfirmation = _ => {
    this.props.client.mutate({
        mutation: RESEND_CONFIRMATION,
    }).then(response => {
      console.log('done')
    }).catch(error => {
      console.log(error)
    })

    this.setState({
      hasSentConfirmation: true
    })
  }

  render(){
    const {
      user
    } = this.props

    return (
      <header onClick={e => e.stopPropagation()} style={{ backgroundColor: '#38d0a7' }}>
        <div className="header-logo">
          <img src={'/images/logo.png'} className="logo" />
        </div>
        { !user.activated &&
          <span className="inactive-notice">
            <FontAwesome name="exclamation-triangle" />
            { !this.state.hasSentConfirmation ?
              <span>
                You haven't verified your email address yet. <button onClick={this.resendConfirmation}>Click here</button> to re-send the confirmation.
              </span>
              :
              <span>
                Confirmation re-sent! Please check your email ({ user.email }).
              </span>
            }
          </span>
        }
        <div ref={node => this.node = node} className="header-right" onClick={() => {this.toggleMenu()}}>
          <img className="person" src="/images/icons/person-dark.png" />
          <span>
            { this.props.user.firstName } { this.props.user.lastName }
          </span>
          <FontAwesome name={this.state.isMenuOpen ? "caret-up" : "caret-down"} />
          <PopMenu closeCallback={(isOpen) => {this.toggleMenu(isOpen)}} items={this.menuItems} isOpen={this.state.isMenuOpen} />
        </div>
      </header>
    )
  }
}

export default withApollo(compose(graphql(OPEN_MODAL, { name: 'openModal' }))(Header))
